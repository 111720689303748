import { SupportedEVMChainIds } from './chains';

export const ERC_20_TOKENS = {
  // DAI on mainnet
  [SupportedEVMChainIds.EthereumMainnet]: '0x6b175474e89094c44da98b954eedeac495271d0f',
  // Our token on polygon https://polygonscan.com/address/0xE97AF6E66A544495A39Cfda21E12fBA39aAb4b0C#code
  [SupportedEVMChainIds.PolygonMainnet]: '0xE97AF6E66A544495A39Cfda21E12fBA39aAb4b0C',
  // Our token on sepolia https://sepolia.etherscan.io/address/0x9f18263B146a7844C30AA5229cDe5578BBcC5b24#code
  [SupportedEVMChainIds.EthereumSepolia]: '0x9f18263B146a7844C30AA5229cDe5578BBcC5b24',
  // Our token on amoy https://amoy.polygonscan.com/address/0xFcf7d751Cd37d5713a70a9fC0CF1ba343773F407#code
  [SupportedEVMChainIds.PolygonAmoy]: '0xFcf7d751Cd37d5713a70a9fC0CF1ba343773F407',
  // Our token on base sepolia https://sepolia.basescan.org/address/0xf88608204B8de0a7AE72cb0845f0471975Ddb8F1#code
  [SupportedEVMChainIds.BaseSepolia]: '0xf88608204B8de0a7AE72cb0845f0471975Ddb8F1',
  // Our token on base mainnet https://basescan.org/address/0xd1dFdB375473C0740F2D6e4Aa3e37E8b2E7bf5A3#code
  [SupportedEVMChainIds.BaseMainnet]: '0xd1dFdB375473C0740F2D6e4Aa3e37E8b2E7bf5A3',
  // Monad testnet
  [SupportedEVMChainIds.MonadTestnet]: '0x8499eC8E8f11b321EF9c8Def25F0427c21624B20',
};

export const ERC721_TOKENS = {
  // Our NFT on mainnet
  [SupportedEVMChainIds.EthereumMainnet]: '',
  // Our NFT on polygon https://polygonscan.com/address/0xc9b19A5aA9E81DC205a93fe17d2dA6F9A2894538#code
  [SupportedEVMChainIds.PolygonMainnet]: '0xc9b19A5aA9E81DC205a93fe17d2dA6F9A2894538',
  // Our NFT on sepolia https://sepolia.etherscan.io/address/0xA0e2e4888B0FbF620f86acD9A07461CE30585BFe#code
  [SupportedEVMChainIds.EthereumSepolia]: '0xA0e2e4888B0FbF620f86acD9A07461CE30585BFe',
  // Our NFT on amoy https://amoy.polygonscan.com/address/0xe0c1df9d33869bfDBC0D319BAAa1D827671F8f0d#code
  [SupportedEVMChainIds.PolygonAmoy]: '0xe0c1df9d33869bfDBC0D319BAAa1D827671F8f0d',
  // Our NFT on base sepolia https://sepolia.basescan.org/address/0xD265a3F208025c8f57d51Bdb421e2e633211C3D3#code
  [SupportedEVMChainIds.BaseSepolia]: '0xD265a3F208025c8f57d51Bdb421e2e633211C3D3',
  // Our NFT on base mainnet https://basescan.org/address/0xF1eF7E23A71575F7c45ae918256019aAaF238B44#code
  [SupportedEVMChainIds.BaseMainnet]: '0xF1eF7E23A71575F7c45ae918256019aAaF238B44',
  // Monad testnet
  [SupportedEVMChainIds.MonadTestnet]: '0x7792385B6C21FCC33ec94ddD4Bf9fAd9A9A7769D',
};

export const TOKEN_LOCKER = {
  // Token locker on mainnet
  [SupportedEVMChainIds.EthereumMainnet]: '',
  // Token locker on polygon https://polygonscan.com/address/0x52B5A48654783c25878661a093d7d31745b3a4d4#code
  [SupportedEVMChainIds.PolygonMainnet]: '0x52B5A48654783c25878661a093d7d31745b3a4d4',
  // Token locker on sepolia https://sepolia.etherscan.io/address/0xA535833eBDee7531f6D52073C120548d39BD2e95#code
  [SupportedEVMChainIds.EthereumSepolia]: '0xA535833eBDee7531f6D52073C120548d39BD2e95',
  // Token locker on amoy https://amoy.polygonscan.com/address/0x75eE2509a8C1515e18648bbDe416c2C03A3a046C#code
  [SupportedEVMChainIds.PolygonAmoy]: '0x75eE2509a8C1515e18648bbDe416c2C03A3a046C',
  // Token locker on base sepolia https://sepolia.basescan.org/address/0x904730A0933DaB76ce91D518AF1694dC0F7b8DdB#code
  [SupportedEVMChainIds.BaseSepolia]: '0x904730A0933DaB76ce91D518AF1694dC0F7b8DdB',
  // Token locker on base mainnet https://basescan.org/address/0xFcf7d751Cd37d5713a70a9fC0CF1ba343773F407#code
  [SupportedEVMChainIds.BaseMainnet]: '0xFcf7d751Cd37d5713a70a9fC0CF1ba343773F407',
  // Monad testnet
  [SupportedEVMChainIds.MonadTestnet]: '0xfC3f361D580A1Dc165E4204BC38ac7839463F1b7',
};
