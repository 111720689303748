/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useEffect, useMemo, useRef } from 'react';
import { useAccount, useSignMessage, useDisconnect, useSendTransaction, useConnect } from 'wagmi';
import { mainnet, polygon, polygonAmoy, sepolia } from 'wagmi/chains';
import { http, createConfig } from 'wagmi';

import { WagmiProvider } from 'wagmi';
import Button from '../../components/Button';
import { TestId } from '../../components/TestId';
import Sidebar from '../../components/Sidebar';

import { parseGwei } from 'viem';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { SupportedEVMChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppWrapper } from '../../components/AppWrapper';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import styled from 'styled-components';
import { GRAY } from '../../constants';

// =============================================================================
// Styles
// =============================================================================

const UnprivilegedActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  margin: 12px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Title = styled.h4`
  color: ${GRAY};
`;

// =============================================================================
// wagmi configuration
// =============================================================================

const config = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [polygon.id]: http(),
    [polygonAmoy.id]: http(),
  },
});

// =============================================================================
// Constants
// =============================================================================

const MESSAGE = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';

// =============================================================================
// App Component
// =============================================================================

const AppComponent = () => {
  const { createLog, logs, clearLogs, logsVisibility, toggleLogs } = useLogs();
  const { address, status } = useAccount();
  let prevStatus = useRef(status);
  useEffect(() => {
    switch (status) {
      case 'disconnected':
        if (status === prevStatus.current) break;
        createLog({
          status: 'warning',
          method: 'disconnect',
          message: 'user disconnected wallet',
        });
        break;
      case 'connected':
        createLog({
          status: 'success',
          method: 'connect',
          message: `Connected to app with account ${address}}`,
        });
        break;
      case 'connecting':
        createLog({
          status: 'info',
          method: 'connect',
          message: 'user connecting...',
        });
        break;
    }
    prevStatus.current = status;
  }, [createLog, address, status]);

  const { connectors, connect } = useConnect();
  const { signMessage } = useSignMessage();
  const { sendTransaction } = useSendTransaction();
  const { disconnect } = useDisconnect();

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Sign Message',
        chainIds: [SupportedEVMChainIds.EthereumMainnet],
        onClick: () =>
          signMessage(
            {
              message: MESSAGE,
              account: address,
            },
            {
              onSettled(data, error) {
                if (error) {
                  createLog({
                    status: 'error',
                    method: 'signMessage',
                    message: error.message,
                  });
                  return;
                }
                createLog({
                  status: 'success',
                  method: 'signMessage',
                  message: `Message signed: ${data}`,
                });
              },
            }
          ),
      },
      {
        name: 'Send Transaction (burn 1 wei on Goerli)',
        chainIds: [SupportedEVMChainIds.EthereumMainnet],
        onClick: () =>
          sendTransaction(
            {
              to: '0x0000000000000000000000000000000000000000', // Common for burning ETH
              value: parseGwei('1', 'wei'),
            },
            {
              onSettled: (data, error) => {
                if (error) {
                  createLog({
                    status: 'error',
                    method: 'eth_sendTransaction',
                    message: `Error occurred: ${error.message}`,
                  });
                  return;
                }
                createLog({
                  status: 'success',
                  method: 'eth_sendTransaction',
                  message: `Transaction success: ${data}`,
                });
              },
            }
          ),
      },
      {
        name: 'Disconnect',
        chainIds: [SupportedEVMChainIds.EthereumMainnet],
        onClick: () => disconnect(),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMessage, sendTransaction, disconnect]);

  return (
    <AppWrapper>
      <Sidebar logsVisibility={logsVisibility} toggleLogs={toggleLogs} activePath="/wagmi-sandbox">
        {address && <ConnectedAs addresses={{ evm: address, solana: null }} />}
        {!address && (
          <UnprivilegedActions>
            <Column>
              <Title>Connect</Title>
              <div>
                <Button
                  key={connectors[0].uid}
                  onClick={() => connect({ connector: connectors[0] })}
                  data-testid="connect"
                >
                  Connect to Phantom
                </Button>

                <TestId id="connect" />
              </div>
            </Column>
          </UnprivilegedActions>
        )}
        <ActionButtons connected={!!address} connectedMethods={connectedMethods} />
      </Sidebar>
      {logsVisibility && <Logs connected={!!address} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

// =============================================================================
// Main Component
// =============================================================================
const queryClient = new QueryClient();

const App = () => {
  return (
    <LogsProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <AppComponent />
        </QueryClientProvider>
      </WagmiProvider>
    </LogsProvider>
  );
};

export default App;
