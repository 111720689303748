import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar';
import { AppWrapper } from '../../components/AppWrapper';
import styled from 'styled-components';

const BlinksContainer = styled.div`
  max-width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Blink = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
`;

const sleep = (timeInMS) => new Promise((resolve) => setTimeout(resolve, timeInMS));
const loadMessage = 'Loading the Blink...';

const App = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    (async () => {
      // sleep for 2000 ms to give time to inject
      await sleep(2000);
      setShow(true);
    })();
  }, []);

  return (
    <AppWrapper>
      <Sidebar disableLogs={true} logsVisibility={false} toggleLogs={() => {}} activePath="/blinks-sandbox">
        <BlinksContainer>
          {show ? (
            <>
              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/49mnQrB6m0">Loading solana blink</a>
                  </div>
                </div>
              </Blink>
              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/CRTXbz3EnV">Loading donation blink</a>
                  </div>
                </div>
              </Blink>
              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/D0qEfuEZ9Y">Loading monad blink (devnet)</a>
                  </div>
                </div>
              </Blink>
              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/K9VzIHb4lD">Loading monad blink (Testnet)</a>
                  </div>
                </div>
              </Blink>

              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/KtNotSxEBx">Loading monad blink (Polygon)</a>
                  </div>
                </div>
              </Blink>

              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/TPv294pbKI">Loading drift USD deposit</a>
                  </div>
                </div>
              </Blink>
              <Blink data-testid="messageEntry">
                <div data-testid="card.wrapper">
                  <div>
                    <a href="https://t.co/7nh2HkB3Db">Loading solana sign message blink</a>
                  </div>
                </div>
              </Blink>
            </>
          ) : (
            loadMessage
          )}
        </BlinksContainer>
      </Sidebar>
    </AppWrapper>
  );
};

export default App;
